import 'bootstrap';
import 'jquery-drawer';
import Swiper from 'swiper';
(function($) {

    var url = location.href// URLの取得
    var path = location.pathname// パスの取得
    var param = location.search// パラメーターの取得
    var anc = location.hash// ページ内アンカーの取得
    path = path.replace( 'sample_iemamori/', '' );// 本番環境では削除
    if ( url == 'http://example.com/' ){}
    if ( path.match(/cart\//) ){}
    if ( path == '/login.html' ){
        Action.checkFullPageBackgroundImage();
    }
    if ( param == '?search=123' ){}
    if ( anc == '#anchor01' ){}

    $('.drawer').drawer();

    var swiper = new Swiper('.swiper-container', {
        spaceBetween: 30,
        effect: 'fade',
        pagination: {
            el: '.swiper-pagination',
            clickable: true,
        },
        navigation: {
            nextEl: '.swiper-button-next',
            prevEl: '.swiper-button-prev',
        },
        autoplay: {
            delay: 5000,
        },
        loop:true
    });

})(jQuery);import 'bootstrap';

$(function(){
    $('a.page_link').on('click',function(){
        var speed = 500;
        var href= $(this).attr("href");
        var target = $(href == "#" || href == "" ? 'html' : href);
        var position = target.offset().top - 60;
        $("html, body").animate({scrollTop:position}, speed, "swing");
        return false;
    });
    $('#modal_btn').on('click',function(){
        $('#modal_cont').fadeIn();
    });
    $('#modal_cont').on('click',function(e){
        if(e.target.id == 'modal_cont'){
            $('#modal_cont').fadeOut();
        }
    });
});